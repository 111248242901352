<template>
  <div class="warningClass">
    <div class="page-title">班次提醒管理</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>司机姓名</div>
      <div class="w150 mL10">
        <el-input placeholder="请输入司机姓名" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">司机工号</div>
      <div class="w150 mL10">
        <el-input placeholder="请输入司机工号" style="width: 100%;" clearable v-model="code"></el-input>
      </div>
      <div class="mL20">班次时间</div>
      <div class="w400 mL10">
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        end-placeholder="结束时间" style="width: 100%;" clearable v-model="date">
        </el-date-picker>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style">
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="name" label="司机姓名" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="workNo" label="司机工号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="carNo" label="车辆信息" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="classes" label="班次" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="workTime" label="班次时间" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: '',
      code: '',
      date: [],
      tableData: [],
      pageNo: 1,
      total: 0,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.code = ''
      this.date = []
      this.clickSearch()
    },
    //提醒
    clickDeal(item) {
      this.$postForm('/warnings/warningUpdate', {
        id: item.id
      }).then((res) => {
        if (res) {
          this.$message.success('提醒成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    getData() {
      this.$postForm('/push/pushPage', {
        name: this.name,
        WorkCode: this.code,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.warningClass {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
